<template>
  <el-dialog class="select-dialog" :title="$t('common.select') + $l('workOrder.faultReason', '故障原因')"
    :visible.sync="dialogVisible" width="80%" top="2vh" append-to-body>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="faultReason">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$l('workOrder.faultReason', '故障原因')">
          <el-select v-model="search.faultType" :placeholder="$t('common.pleaseSelect')"
            :style="{ width: $l('150px', '100px') }" clearable>
            <el-option v-for="item in faultTypeList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
      </template>
      <el-table-column prop="faultType" :label="$l('workOrder.faultReason', '故障原因')" width="300px">
        <template slot-scope="scope">
          <span v-if="scope.row.faultType === 1">
            <el-tag type="primary">{{ $l("workOrder.humanCauses", "人为原因") }}</el-tag>
          </span>
          <span v-if="scope.row.faultType === 2"><el-tag
              type="success">{{ $l("workOrder.externalCauses", "外部原因") }}</el-tag></span>
          <span v-if="scope.row.faultType === 3"><el-tag type="info">{{ $l("workOrder.doorSystem", "门系统") }}</el-tag></span>
          <span v-if="scope.row.faultType === 4"><el-tag
              type="warning">{{ $l("workOrder.tractionSystem", "曳引系统") }}</el-tag></span>
          <span v-if="scope.row.faultType === 5"><el-tag
              type="danger">{{ $l("workOrder.guidanceSystem", "导向系统") }}</el-tag></span>
          <span v-if="scope.row.faultType === 6"><el-tag type="primary">{{ $l("workOrder.liftCar", "轿厢") }}</el-tag></span>
          <span v-if="scope.row.faultType === 7"><el-tag
              type="success">{{ $l("workOrder.controlSystem", "控制系统") }}</el-tag></span>
          <span v-if="scope.row.faultType === 8"><el-tag
              type="info">{{ $l("workOrder.electricalSystem", "电气系统") }}</el-tag></span>
          <span v-if="scope.row.faultType === 9"><el-tag
              type="warning">{{ $l("workOrder.protectionDevice", "安全保护装置") }}</el-tag></span>
        </template>
      </el-table-column>
      <el-table-column prop="faultCode" :label="$l('elevator.faultCode', '故障代码')" width="300px"></el-table-column>
      <el-table-column prop="faultDesc" :label="$l('workOrder.faultAnalysis', '故障分析')"></el-table-column>
      <el-table-column :label="$t('common.operation')" width="100" align="center">
        <template slot-scope="scope">
          <el-button type="success" @click="handleSelect(scope.row)">{{ $t("common.select") }}</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      loading: true,
      search: {
        filter: "",
        faultType: "",
      },
      faultTypeList: [
        { value: 1, label: this.$l("workOrder.humanCauses", "人为原因") },
        { value: 2, label: this.$l("workOrder.externalCauses", "外部原因") },
        { value: 3, label: this.$l("workOrder.doorSystem", "门系统") },
        { value: 4, label: this.$l("workOrder.tractionSystem", "曳引系统") },
        { value: 5, label: this.$l("workOrder.guidanceSystem", "导向系统") },
        { value: 6, label: this.$l("workOrder.liftCar", "轿厢") },
        { value: 7, label: this.$l("workOrder.controlSystem", "控制系统") },
        { value: 8, label: this.$l("workOrder.electricalSystem", "电气系统") },
        { value: 9, label: this.$l("workOrder.protectionDevice", "安全保护装置") },
      ],
    };
  },
  methods: {
    open() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.getList(1);
      });
    },
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    handleSelect(row) {
      this.$emit("select", row);
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
