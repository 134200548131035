<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :title="$l('workOrder.orderConfirm','工单确认')"
    width="500px"
    top="10vh"
    append-to-body>
    <el-form
      ref="form"
      :model="workOrder"
      class="form"
      :label-width="$l('120px','80px')">
      <el-form-item :label="$l('workOrder.faultReason','故障原因')" prop="faultReasonId" :rules="$rule.notNull">
        <el-input
          v-model="workOrder.faultReasonDesc"
          :placeholder="$t('common.pleaseSelect')"
          readonly>
          <el-button slot="append" icon="el-icon-search" @click="$refs.faultReasonSelect.open()"></el-button>
        </el-input>
        <fault-reason-select ref="faultReasonSelect" @select="handleSelect"></fault-reason-select>
      </el-form-item>
      <el-form-item :label="$l('workOrder.handleMethod','处理方式')" prop="handleMethod" :rules="$rule.notNull">
        <el-input v-model="workOrder.handleMethod" type="textarea"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible = false">{{$l("elevatorNetApply.cancel", "取消")}}</el-button>
      <el-button
        :loading="submitLoading"
        type="primary"
        @click="submit">{{$l("elevatorNetApply.save", "保存")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>

  import FaultReasonSelect from "../faultReason/FaultReasonSelect";

  export default {
    components: {FaultReasonSelect},
    data() {
      return {
        workOrder: {
          handleMethod: "",
          faultReasonId: "",
          faultReasonDesc: "",
          handleState: "",
          workOrderNo: "",
          id: "",
        },
        dialogVisible: false,
        submitLoading: false,
      };
    },
    methods: {
      open(row) {
        this.workOrder.id = row.id;
        this.workOrder.handleState = row.handleState;
        this.workOrder.workOrderNo = row.workOrderNo;
        this.dialogVisible = true;
      },
      submit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            if (this.workOrder.handleState === 1) {
              this.$message.error(this.$t("workOrder.tip.stoped"));
              return;
            }
            this.$confirm(this.$t("workOrder.workOrderNo") + " " + this.workOrder.workOrderNo + " ，" + this.$t("workOrder.tip.want") + this.$t("workOrder.confirm") + this.$t("workOrder.tip.mark"), this.$t("common.tips"), {
              confirmButtonText: this.$t("common.confirm"),
              cancelButtonText: this.$t("common.cancel"),
              type: "warning",
            }).then(() => {
              this.$http.post("workOrders" + "/confirmOrder/" + this.workOrder.id + "/" + this.workOrder.faultReasonId + "/" + this.workOrder.handleMethod).then(res => {
                this.$emit("submit-success");
                this.submitLoading = false;
                this.$message.success(this.$l("common.tip.saveSuccess", "保存成功"));
              }).catch((error) => {
                this.submitLoading = false;
                this.$message.error(this.$t("workOrder.confirm") + this.$t("common.tip.operationFailure") + "，" + error.response.data.message);
              });
            });
          }
        });
      },
      handleSelect(row) {
        this.workOrder.faultReasonId = row.id;
        this.workOrder.faultReasonDesc = row.faultDesc;
      },
    },
  };
</script>

<style lang="scss">
.el-message-box{
  padding-bottom: 5px;
  margin-top: -20vh;
}
</style>
