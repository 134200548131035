<template>
  <el-input class="vm-select-input" :placeholder="$t('common.pleaseSelect')" :value="value" :clearable="clearable"
    :maxlength="0" @change="change" @clear="clear">
    <el-button slot="append" icon="el-icon-search" @click="onSelect"></el-button>
  </el-input>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    clearable: Boolean,
  },
  methods: {
    onSelect() {
      this.$emit("select");
    },
    change(val) {
      this.$emit("input", val);
    },
    clear(event) {
      this.$emit("clear");
    },
  },
};
</script>

<style lang="scss">
.vm-select-input {
  .el-input__inner {
    border-radius: 8px 0 0 8px !important;
    border-right: 1px solid #EBEEF5!important;
  }
  .el-input-group__append {
    border: none!important;
    border-radius: 0 8px 8px 0;
    background-color: #f2f2f2;
  }
}
</style>
