<template>
  <div>
    <vm-table
      ref="vmTable"
      v-loading="loading"
      :filter.sync="search"
      url="workOrders/web"
      @clear="clearDateRange">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input
            v-model.trim="search.filter"
            :placeholder="$t('common.search')"
            style="width: 150px"
            clearable></el-input>
        </vm-search>
        <vm-search :label="$l('workOrder.workOrderType', '工单类型')">
          <el-select
            v-model="search.workOrderType"
            :placeholder="$t('common.pleaseSelect')"
            :style="{ width: $l('150px', '100px') }"
            clearable>
            <el-option
              v-for="item in workOrderTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$l('workOrder.alarmType', '报警类型')">
          <el-select
            v-model="search.alarmType"
            :placeholder="$t('common.pleaseSelect')"
            :style="{ width: $l('150px', '100px') }"
            clearable>
            <el-option v-for="item in closedTypes" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$l('workOrder.workOrderStatus', '工单状态')">
          <el-select
            v-model="search.workOrderRecordState"
            :placeholder="$t('common.pleaseSelect')"
            :style="{ width: $l('150px', '100px') }"
            clearable>
            <el-option
              v-for="item in workOrderStates"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('elevator.useUnit')">
          <vm-select-input
            v-model="search.useUnitName"
            clearable
            @clear="search.useUnitId = ''"
            @select="$refs.selectUseUnitProperty.open()"></vm-select-input>
          <use-unit-select ref="selectUseUnitProperty" @select="onSelectUseUnit"></use-unit-select>
        </vm-search>
        <vm-search v-if="$i18n.isCn" :label="$l('elevator.accessPlatform', '对接平台')">
          <el-select
            v-model="search.integrationKey"
            :placeholder="$t('elevator.accessPlatform')"
            :style="{ width: $l('160px', '150px') }"
            clearable>
            <el-option
              v-for="item in integrationList"
              :key="item.integrationKey"
              :label="item.name"
              :value="item.integrationKey"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('workOrder.alarmTime')">
          <el-date-picker
            v-model="dateRange"
            value-format="yyyy-MM-dd"
            type="daterange"
            :range-separator="$t('common.to')"
            :start-placeholder="$t('common.startDate')"
            :end-placeholder="$t('common.endDate')"
            @change="handleDateChange">
          </el-date-picker>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button class="addBtn" @click="$refs.editPage.open(0)">
          {{ $l("elevatorNetApply.add", "+ 新增") }}
        </el-button>
        <el-button :loading="exportLoading" class="greenBtn" @click="exportExcel">
          {{ $l("elevator.exportExcel", "导出") }}
        </el-button>
      </template>
      <el-table-column prop="workOrderNo" :label="$t('workOrder.workOrderNo')" width="120" align="center">
        <template slot-scope="scope">
          <router-link :to="'/workOrder/' + scope.row.id" target="_blank">{{ scope.row.workOrderNo }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="elevatorNo" :label="$t('elevator.no')" width="120" align="center">
        <template slot-scope="scope">
          <router-link :to="'/elevator/' + scope.row.elevatorId" target="_blank">{{ scope.row.elevatorNo }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="elevatorName" show-overflow-tooltip :label="$t('elevator.name')"></el-table-column>
      <el-table-column prop="useUnitName" show-overflow-tooltip :label="$t('elevator.useUnit')"></el-table-column>
      <el-table-column prop="deviceCode" :label="$t('workOrder.deviceCode')" width="140" align="center"></el-table-column>
      <el-table-column prop="alarmTime" :label="$t('workOrder.alarmTime')" width="140" align="center"></el-table-column>
      <el-table-column prop="alarmType" :label="$l('workOrder.alarmType', '报警类型')" width="100" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.alarmType === 0">
            <el-tag type="warning">{{ $t("workOrder.alarmType_0") }}</el-tag>
          </div>
          <div v-if="scope.row.alarmType === 1">
            <el-tag type="danger">{{ $t("workOrder.alarmType_1") }}</el-tag>
          </div>
          <div v-if="scope.row.alarmType === 2">
            <el-tag type="warning">{{ $t("workOrder.alarmType_2") }}</el-tag>
          </div>
          <div v-if="scope.row.alarmType === 3">
            <el-tag type="warning">{{ $t("workOrder.alarmType_3") }}</el-tag>
          </div>
          <div v-if="scope.row.alarmType === 4">
            <el-tooltip
              class="item"
              effect="light"
              :content="$l('workOrder.deviceReasonTrapped', '设备原因困人')"
              placement="top">
              <el-tag type="danger">{{ "A1" }}</el-tag>
            </el-tooltip>
          </div>
          <div v-if="scope.row.alarmType === 5">
            <el-tooltip
              class="item"
              effect="light"
              :content="$l('workOrder.deviceReasonSafe', '设备原因安全问题')"
              placement="top">
              <el-tag type="warning">{{ "A2" }}</el-tag>
            </el-tooltip>
          </div>
          <div v-if="scope.row.alarmType === 6">
            <el-tooltip
              class="item"
              effect="light"
              :content="$l('workOrder.elevatorDamageOrFault', '损坏停梯或存在故障隐患')"
              placement="top">
              <el-tag type="warning">{{ "B" }}</el-tag>
            </el-tooltip>
          </div>
          <div v-if="scope.row.alarmType === 7">
            <el-tooltip class="item" effect="light" :content="$l('workOrder.minorIssues', '轻微问题')" placement="top">
              <el-tag type="warning">{{ "C" }}</el-tag>
            </el-tooltip>
          </div>
          <div v-if="scope.row.alarmType === 8">
            <el-tooltip
              class="item"
              effect="light"
              :content="$l('workOrder.userReasonNoTrapped', '用户原因未困人')"
              placement="top">
              <el-tag type="warning">{{ "D1" }}</el-tag>
            </el-tooltip>
          </div>
          <div v-if="scope.row.alarmType === 9">
            <el-tooltip
              class="item"
              effect="light"
              :content="$l('workOrder.userReasonTrapped', '用户原因困人')"
              placement="top">
              <el-tag type="danger">{{ "D2" }}</el-tag>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="workOrderType" :label="$l('workOrder.workOrderType', '工单类型')" width="100" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.workOrderType === 1">
            <el-tag>{{ $l("workOrder.manualAlarm", "人工报警") }}</el-tag>
          </div>
          <div v-if="scope.row.workOrderType === 2">
            <el-tag type="success">{{ $l("workOrder.automaticAlarm", "自动报警") }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="recordState" :label="$t('workOrder.workOrderStatus')" width="120">
        <template slot-scope="scope">
          <div v-if="scope.row.recordState === 0">
            <el-tag type="danger">{{ $t("workOrder.recordState_0") }}</el-tag>
          </div>
          <div v-if="scope.row.recordState === 1">
            <el-tag>{{ $t("workOrder.recordState_1") }}</el-tag>
          </div>
          <div v-if="scope.row.recordState === 2">
            <el-tag>{{ $t("workOrder.recordState_2") }}</el-tag>
          </div>
          <div v-if="scope.row.recordState === 3 && scope.row.finishState === 0">
            <el-tag>{{ $t("workOrder.recordState_3") }}</el-tag>
          </div>
          <div v-if="scope.row.recordState === 3 && scope.row.finishState === 1">
            <el-tag>{{ $t("workOrder.recordState_4") }}</el-tag>
          </div>
          <div v-if="scope.row.recordState === 3 && scope.row.finishState === 2">
            <el-tag>{{ $t("workOrder.recordState_5") }}</el-tag>
          </div>
          <div v-if="scope.row.recordState === 4 && scope.row.confirmState === 0">
            <el-tag type="success">{{ $t("workOrder.recordState_6") }}</el-tag>
          </div>
          <div v-if="scope.row.recordState === 4 && scope.row.confirmState === 1">
            <el-tag type="success">{{ $t("workOrder.recordState_7") }}</el-tag>
          </div>
          <div v-if="scope.row.recordState === 5">
            <el-tag type="info">{{ $t("workOrder.recordState_8") }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('common.operation')" width="160" fixed="right" class-name="vm-table_operate">
        <template slot-scope="scope">
          <span v-if="scope.row.recordState === 0">
            <el-button v-if="editAuth" type="text" @click="handleEdit(scope.row)">{{ $t("common.edit") }}</el-button>
            <el-button type="text" @click="handleReceive(scope.row)">{{ $t("workOrder.received") }}</el-button>
            <el-button v-if="cancelAuth" type="text" class="successBtn" @click="$refs.orderCancel.open(scope.row)">{{
                $t("workOrder.canceled") }}</el-button>
            <el-button v-else-if="deleteAuth" type="text" class="operateDelBtn" @click="handleDelete(scope.row)">{{
                $t("common.delete") }}</el-button>
          </span>
          <span v-else-if="scope.row.recordState === 1">
            <el-button v-if="editAuth" type="text" @click="handleEdit(scope.row)">{{ $t("common.edit") }}</el-button>
            <el-button type="text" class="infoBtn" :disabled="true">{{ $t("workOrder.finished") }}</el-button>
            <el-button v-if="cancelAuth" type="text" class="successBtn" @click="$refs.orderCancel.open(scope.row)">{{
                $t("workOrder.canceled") }}</el-button>
            <el-button type="text" class="operateDelBtn" :disabled="true">{{ $t("common.delete") }}</el-button>
          </span>
          <span v-else-if="scope.row.recordState === 2">
            <el-button v-if="editAuth" type="text" @click="handleEdit(scope.row)">{{ $t("common.edit") }}</el-button>
            <el-button type="text" @click="handleFinish(scope.row)">{{ $t("workOrder.completed") }}</el-button>
            <el-button v-if="cancelAuth" type="text" class="successBtn" @click="$refs.orderCancel.open(scope.row)">{{
                $t("workOrder.canceled") }}</el-button>
            <el-button type="text" class="operateDelBtn" :disabled="true">{{ $t("common.delete") }}</el-button>
          </span>
          <span v-else-if="scope.row.recordState === 3">
            <el-button v-if="editAuth" type="text" @click="handleEdit(scope.row)">{{ $t("common.edit") }}</el-button>
            <el-button v-if="confirmAuth" type="text" @click="$refs.workOrderConfirm.open(scope.row)">{{
                $t("workOrder.confirmed") }}</el-button>
            <el-button v-if="cancelAuth" type="text" class="successBtn" @click="$refs.orderCancel.open(scope.row)">{{
                $t("workOrder.canceled") }}</el-button>
            <el-button type="text" class="operateDelBtn" :disabled="true">{{ $t("common.delete") }}</el-button>
            <work-order-confirm ref="workOrderConfirm" @submit-success="getList(1)"></work-order-confirm>
          </span>
          <span v-else-if="scope.row.recordState === 4">
            <el-button type="text" :disabled="true">{{ $t("common.edit") }}</el-button>
            <el-button type="text" class="infoBtn" :disabled="true">{{ $t("workOrder.finished") }}</el-button>
            <el-button v-if="cancelAuth" type="text" class="successBtn" @click="$refs.orderCancel.open(scope.row)">{{
                $t("workOrder.canceled") }}</el-button>
            <el-button type="text" class="operateDelBtn" :disabled="true">{{ $t("common.delete") }}</el-button>
          </span>
          <span v-else-if="scope.row.recordState === 5">
            <el-button type="text" :disabled="true">{{ $t("common.edit") }}</el-button>
            <el-button type="text" class="infoBtn" :disabled="true">{{ $t("workOrder.finished") }}</el-button>
            <el-button type="text" class="successBtn" :disabled="true">{{ $t("workOrder.canceled") }}</el-button>
            <el-button type="text" class="operateDelBtn" :disabled="true">{{ $t("common.delete") }}</el-button>
          </span>
          <work-order-cancel ref="orderCancel" @submit-success="getList(-1)"></work-order-cancel>
        </template>
      </el-table-column>
    </vm-table>
    <edit-dialog ref="editPage" @save-success="getList($event ? 1 : currentPage)"></edit-dialog>
  </div>
</template>
<script>
  import EditDialog from "./WorkOrderEdit.vue";
  import VmSelectInput from "../../components/VmSelectInput";
  import UseUnitSelect from "../company/useUnitCom/UseUnitSelect.vue";
  import WorkOrderConfirm from "./WorkOrderConfirm";
  import WorkOrderCancel from "@/views/workOrder/WorkOrderCancel";

  const moduleName = "workOrders";

  export default {
    components: { WorkOrderConfirm, EditDialog, VmSelectInput, UseUnitSelect,WorkOrderCancel },
    data() {
      return {
        editAuth: this.$auth(301),
        deleteAuth: this.$auth(303),
        cancelAuth: this.$auth(304),
        confirmAuth: this.$auth(307),
        adSearch: false,
        loading: false,
        exportLoading: false,
        search: {
          filter: "",
          integrationKey: "",
          alarmType: "",
          workOrderRecordState: "",
          startTime: "",
          endTime: "",
          useUnitId: "",
          useUnitName: "",
          workOrderType: "",
        },
        integrationList: [],
        workOrderTypes: [
          {
            value: 1,
            label: this.$l("workOrder.manualAlarm", "人工报警"),
          },
          {
            value: 2,
            label: this.$l("workOrder.automaticAlarm", "自动报警"),
          }],
        closedTypes: [
          {
            value: 0,
            label: this.$t("workOrder.alarmType_0"),
          },
          {
            value: 1,
            label: this.$t("workOrder.alarmType_1"),
          },
          {
            value: 2,
            label: this.$t("workOrder.alarmType_2"),
          },
          {
            value: 3,
            label: this.$t("workOrder.alarmType_3"),
          },
          {
            value: 4,
            label: this.$l("workOrder.deviceReasonTrapped", "人工-A1（设备原因困人）"),
          },
          {
            value: 5,
            label: this.$l("workOrder.deviceReasonSafe", "人工-A2（设备原因安全问题）"),
          },
          {
            value: 6,
            label: this.$l("workOrder.elevatorDamageOrFault", "人工-B（损坏停梯或存在故障隐患）"),
          },
          {
            value: 7,
            label: this.$l("workOrder.minorIssues", "人工-C（轻微问题）"),
          },
          {
            value: 8,
            label: this.$l("workOrder.userReasonNoTrapped", "人工-D1（用户原因未困人）"),
          },
          {
            value: 9,
            label: this.$l("workOrder.userReasonTrapped", "人工-D2（用户原因困人）"),
          }],
        workOrderStates: [
          {
            value: 1,
            label: this.$t("workOrder.recordState_0"),
          },
          {
            value: 2,
            label: this.$t("workOrder.recordState_1"),
          },
          {
            value: 3,
            label: this.$t("workOrder.recordState_2"),
          },
          {
            value: 4,
            label: this.$t("workOrder.recordState_3"),
          },
          {
            value: 5,
            label: this.$t("workOrder.recordState_4"),
          },
          {
            value: 6,
            label: this.$t("workOrder.recordState_5"),
          },
          {
            value: 7,
            label: this.$t("workOrder.recordState_6"),
          },
          {
            value: 8,
            label: this.$t("workOrder.recordState_7"),
          },
          {
            value: 9,
            label: this.$t("workOrder.recordState_8"),
          }],
        dateRange: [],
      };
    },
    mounted() {
      this.getList(1);
      this.getIntegrationList();
    },
    methods: {
      getList(pageIndex) {
        this.$refs.vmTable.getList(pageIndex);
      },
      exportExcel() {
        this.exportLoading = true;
        let params = {
          startTime: this.dateRange != null ? this.dateRange[0] : "",
          endTime: this.dateRange != null ? this.dateRange[1] : "",
          filter: this.search.filter,
          integrationKey: this.search.integrationKey,
          alarmType: this.search.alarmType,
          workOrderRecordState: this.search.workOrderRecordState,
          useUnitId: this.search.useUnitId,
          useUnitName: this.search.useUnitName,
          workOrderType: this.search.workOrderType,
        };
        this.$api
          .exportFile("workOrders/export", params, 60)
          .then(res => {
            this.exportLoading = false;
            let blob = new Blob([res.data]);
            let fileName = "急修工单.xlsx";
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, fileName);
            } else {
              let a = document.createElement("a");
              a.href = URL.createObjectURL(blob);
              a.download = fileName;
              a.style.display = "none";
              document.body.appendChild(a);
              a.click();
              URL.revokeObjectURL(a.href);
              document.body.removeChild(a);
            }
          })
          .catch(() => {
            this.exportLoading = false;
          });
      },
      getIntegrationList() {
        this.$http.get("integrations/list").then(({ data }) => {
          this.integrationList = data;
        });
      },
      handleDateChange(dateRange) {
        if (dateRange != null) {
          this.search.startTime = dateRange[0];
          this.search.endTime = dateRange[1];
        } else {
          this.search.startTime = "";
          this.search.endTime = "";
        }
      },
      handleDelete(row) {
        this.$confirm(this.$t("common.delete") + " " + this.$t("workOrder.workOrderNo") + " " + row.workOrderNo + "," + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$api.deleteById(moduleName, row.id).then(res => {
            this.getList(1);
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          }).catch(() => {
            this.$message.error(this.$t("common.tip.deleteError"));
          });
        });
      },
      handleAdd() {
        this.$refs.editPage.open(0);
      },
      handleEdit(row) {
        this.$refs.editPage.open(row.id);
      },
      handleReceive(row) {
        if (row.handleState === 1) {
          this.$message.error(this.$t("workOrder.tip.stop"));
          return;
        }
        this.$confirm(this.$t("workOrder.workOrderNo") + " " + row.workOrderNo + " ，" + this.$t("workOrder.tip.want") + this.$t("workOrder.receive") + this.$t("workOrder.tip.mark"), this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$http.post(moduleName + "/acceptAlarm/" + row.id).then(res => {
            this.getList(1);
          }).catch((error) => {
            this.$message.error(this.$t("workOrder.receive") + this.$t("common.tip.operationFailure") + "，" + error.response.data.message);
          });
        });
      },
      handleArrive(row) {
        if (row.handleState === 1) {
          this.$message.error(this.$t("workOrder.tip.stoped"));
          return;
        }
        this.$confirm(this.$t("workOrder.workOrderNo") + " " + row.workOrderNo + " ，" + this.$t("workOrder.tip.want") + this.$t("workOrder.complete") + this.$t("workOrder.tip.mark"), this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$http.post(moduleName + "/arrive/" + row.id).then(res => {
            this.getList(1);
          }).catch((error) => {
            this.$message.error(this.$t("workOrder.complete") + this.$t("common.tip.operationFailure") + "，" + error.response.data.message);
          });
        });
      },
      handleFinish(row) {
        if (row.handleState === 1) {
          this.$message.error(this.$t("workOrder.tip.stoped"));
          return;
        }
        this.$confirm(this.$t("workOrder.workOrderNo") + " " + row.workOrderNo + " ，" + this.$t("workOrder.tip.want") + this.$t("workOrder.complete") + this.$t("workOrder.tip.mark"), this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$http.post(moduleName + "/finish/" + row.id).then(res => {
            this.getList(1);
          }).catch((error) => {
            this.$message.error(this.$t("workOrder.complete") + this.$t("common.tip.operationFailure") + "，" + error.response.data.message);
          });
        });
      },
      // handleConfirm(row) {
      //   // if (row.handleState === 1) {
      //   //   this.$message.error(this.$t("workOrder.tip.stoped"));
      //   //   return;
      //   // }
      //   // this.$confirm(this.$t("workOrder.workOrderNo") + " " + row.workOrderNo + " ，" + this.$t("workOrder.tip.want") + this.$t("workOrder.confirm") + this.$t("workOrder.tip.mark"), this.$t("common.tips"), {
      //   //   confirmButtonText: this.$t("common.confirm"),
      //   //   cancelButtonText: this.$t("common.cancel"),
      //   //   type: "warning",
      //   // }).then(() => {
      //   //   this.$api.getData(moduleName + "/confirm/" + row.id).then(res => {
      //   //     this.getList(1);
      //   //   }).catch((error) => {
      //   //     this.$message.error(this.$t("workOrder.confirm") + this.$t("common.tip.operationFailure") + "，" + error.response.data.message);
      //   //   });
      //   // });
      // },
      handleCancel(row) {
        if (row.handleState === 1) {
          this.$message.error(this.$t("workOrder.tip.stoped"));
          return;
        }
        this.$confirm(this.$t("workOrder.workOrderNo") + " " + row.workOrderNo + " ，" + this.$t("workOrder.tip.want") + this.$t("workOrder.cancel") + this.$t("workOrder.tip.mark"), this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$http.post(moduleName + "/cancel/" + row.id).then(res => {
            this.getList(1);
          }).catch((error) => {
            this.$message.error(this.$t("workOrder.cancel") + this.$t("common.tip.operationFailure") + "，" + error.response.data.message);
          });
        });
      },
      onSelectUseUnit(row) {
        this.search.useUnitId = row.id;
        this.search.useUnitName = row.name;
      },
      clearDateRange() {
        this.dateRange = [];
      },
    },
  };
</script>
<style lang="scss" scoped></style>
