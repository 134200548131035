<template>
  <el-dialog
    v-if="dialogVisible"
    class="select-dialog"
    :title="$t('common.select')+$t('user.user')"
    :visible.sync="dialogVisible"
    width="1200px"
    top="2vh"
    append-to-body>
    <vm-table
      ref="vmTable"
      style="padding-top: 10px"
      :loading="loading"
      :filter.sync="search"
      url="users/new"
      :multi-selection="multiSelection"
      @row-click="handleRowClick"
      @selection-change="handleMultiSelectChange">
      <template slot="adSearch">
        <vm-search :label="$l('common.keywordSearch','关键字检索')">
          <el-input v-model.trim="search.keyword" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$l('user.type','用户类型')">
          <el-select v-model="search.userType" :placeholder="$t('common.pleaseSelect')" clearable>
            <el-option
              v-for="item in userTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button
          v-if="multiSelection"
          slot="otherButton"
          style="border-radius: 8px;font-size: 14px;height: 32px"
          @click="multiSelectSure">
          {{$l("elevator.confirmChoice", "确定选择")}}
        </el-button>
      </template>
      <el-table-column prop="username" :label="$t('user.username')"></el-table-column>
      <el-table-column prop="name" :label="$t('user.name')"></el-table-column>
      <el-table-column prop="phone" :label="$t('user.phone')"></el-table-column>
      <el-table-column prop="orgName" :label="$t('user.company')" min-width="100"></el-table-column>
      <el-table-column prop="createTime" label="创建时间"></el-table-column>
      <el-table-column :label="$t('common.operation')" width="60" align="center">
        <template slot-scope="scope">
          <el-button type="text" class="successBtn" @click="handleSelect(scope.row)">{{$t("common.select")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>

<script>
  import {typeToArray} from "@/util/constant";

  export default {
    components: {},
    props: {
      maintWorkerComId: {
        type: Number,
        default: 0,
      },
      comId: {
        type: Number,
        default: 0,
      },
      multiSelection: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        dialogVisible: false,
        companyList: [],
        search: {},
        multiSelect: [],
        loading: false,
        userTypeOptions: typeToArray(this.$t("user.types")),
      };
    },
    methods: {
      open() {
        this.dialogVisible = true;
        this.getList(1);
      },
      getList(pageNum) {
        if (this.maintWorkerComId > 0) {
          this.search.companyId = this.maintWorkerComId;
          this.search.maintWorker = true;
        }
        this.$nextTick(() => {
          this.loading = true;
          this.$refs.vmTable.getList(pageNum);
          this.loading = false;
        });
      },
      handleMultiSelectChange(val) {
        this.multiSelect = val;
      },
      multiSelectSure() {
        this.$emit("multi-select", this.multiSelect);
        this.dialogVisible = false;
      },
      handleSelect(row) {
        this.$emit("select", row);
        this.dialogVisible = false;
      },
      handleRowClick(row, column) {
        if (column && column.label === this.$t("common.operation")) {
          return;
        }
        this.$refs.vmTable.$refs.elTable.toggleRowSelection(row);
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
